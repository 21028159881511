@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

#tooltip {
  position: relative;
  display: inline-block;
}

#tooltip #tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: #397aa3;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  margin-right: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 2;
  top: 6px;
  right: 105%;
}

#tooltip #tooltiptext2 {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  margin-right: 10px;

  /* Position the tooltip */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

#tooltip:hover #tooltiptext {
  visibility: visible;
}

#tooltip:hover #tooltiptext2 {
  visibility: visible;
}
.font-link {
  font-family: 'Rubik', sans-serif;
}

div {
  font-family: 'Rubik', sans-serif;
}


/*

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 

::-webkit-scrollbar-thumb {
  background: #397aa3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #015227; 
}*/

::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering over the thumb */
}

/*.e-schedule .e-vertical-view .e-resource-cells{
  display: -webkit-inline-box!important;
}

.e-schedule .e-vertical-view .e-work-cells {
    width: 100px;
    display: -webkit-inline-box!important;
}*/
